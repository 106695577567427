// Here you can add other styles

.app-body {
  background: #fff;
}

.login-body {
  background: #274680;
}

.app-btn-small {
  background: #3dae2b !important;
  color: #fff !important;
  width: 276px;
  height: 50px;
  border-radius: 30px !important;
  font-size: 11px !important;
}

.app-btn {
  background: #3dae2b !important;
  color: #fff !important;
  width: 276px;
  height: 50px;
  border-radius: 5px !important;
  font-size: 1rem !important;
}

.app-btn-delete {
  background: #000000 !important;
  color: #fff !important;
  width: 276px;
  height: 50px;
  border-radius: 5px !important;
  font-size: 1rem !important;
}

.app-btn-assign {
  background: #65b2e8 !important;
  color: #fff !important;
  width: 276px;
  height: 50px;
  border-radius: 5px !important;
  font-size: 1rem !important;
}

.app-btn-received {
  background: #284680 !important;
  color: #fff !important;
  width: 276px;
  height: 50px;
  border-radius: 5px !important;
  font-size: 1rem !important;
}

.app-btn-cancel {
  background: #fff !important;
  color: #ec5e53 !important;
  border: 2px solid #ec5e53 !important;
  width: 276px;
  height: 50px;
  border-radius: 30px !important;
  font-size: 1rem !important;
}

.black-text-color {
  color: #0a1f31 !important;
  padding-left: 15px;
  font-size: 1rem;
  font-weight: bold;
}

.grey-text-color {
  color: #4b4b53 !important;
}

.sidebar {
  z-index: 0 !important;
}

.login-form {
  height: 3rem !important;
  background: #f7f7f7 !important;
  border: #f7f7f7 !important;
}

.login-actions {
  color: #4b4b53 !important;
  margin-top: 0.6rem;
}
.sidebar .nav-link:hover {
  color: #fff;
  background: rgba(0, 0, 0, 0.1) !important;
}

.sidebar .nav-link.active {
  color: #fff;
  background: rgba(0, 0, 0, 0.1) !important;
}

.sidebar .nav-link .badge {
  color: #707070 !important;
  background-color: #fff !important;
  border-radius: 1.5rem;
  width: 1.5rem;
  height: 1.5rem;
  padding-top: 6px;
  margin-right: 20px;
}

.sidebar .sidebar-footer {
  flex: 0 0 auto;
  padding: 0;
  background: rgba(0, 0, 0, 0);
  border-top: 3px solid rgba(0, 0, 0, 0.1);
}

.sidebar .sidebar-footer .nav-item {
  position: relative;
  margin: 0;
  transition: background 0.3s ease-in-out;
}

.sidebar .sidebar-footer .nav-link {
  display: block;
  padding: 0.75rem 1rem;
  color: #fff;
  text-decoration: none;
  background: transparent;
  height: 80px;
  padding-top: 26px;
}

.sidebar .sidebar-header {
  height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: rgba(0, 0, 0, 0);
  border-bottom: 3px solid rgba(0, 0, 0, 0.1);
}

.sidebar .nav {
  padding-top: 10px;
}

.nav-bar-name {
  margin-top: 10px;
  font-size: 1.2rem;
  font-weight: bold;
  color: white;
}

.nav-bar-current-order {
  margin-top: 5px;
  font-size: 1rem;
  color: white;
}
.nav-bar-current-order span {
  font-weight: bold;
}

.breadcrumb {
  padding: 1.75rem 2rem !important;
  background-color: transparent !important;
  border-radius: 0 !important;
  border: 0 !important;
}

.breadcrumb-item.active {
  color: #3b424f !important;
  font-size: 1.6rem !important;
  font-weight: 500;
}

.flex-row-dashboard {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
  padding: 1.4rem 3rem !important;
  align-items: center;
}

.border-red {
  border: none !important;
  border-bottom: 6px solid #ec5e53 !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
}

.border-blue {
  border: none !important;
  border-bottom: 6px solid #01d1f2 !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
}

.border-green {
  border: none !important;
  border-bottom: 6px solid #3bc100 !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
}

.count-text {
  font-size: 2.1rem;
}

.count-text-red {
  color: #ec5e53;
}

.count-text-blue {
  color: #01d1f2;
}

.count-text-green {
  color: #01d1f2;
}

.heading {
  color: #3b424f !important;
  font-size: 1.6rem !important;
  font-weight: 400;
  padding: 2rem 0;
}

.table .thead-light th {
  color: #495057;
  background-color: transparent !important;
  border-color: #dee2e6;
}

.table-no-border {
  border: 0 !important;
}

.avatar {
  width: 55px !important;
  height: 55px !important;
  margin-right: 15px !important;
  overflow: hidden;
  border-radius: 30px;
}

.avatar-driver {
  width: 132px !important;
  height: 132px !important;
  margin-right: 15px !important;
  overflow: hidden;
  border-radius: 70px;
}

.driver-name-text {
  color: #3b424f;
  font-size: 1.1rem;
  font-weight: 400;
}

.driver-text {
  color: #3b424f;
  font-size: 0.9rem;
}

.driver-rate-text {
  color: #3b424f;
  font-size: 0.9rem;
  margin-left: 5px;
}

.table-hd {
  color: #000000 !important;
  font-size: 0.8 !important;
}

.modal-content {
  margin-top: 140px;
}

.modal-header {
  border: none !important;
}

.modal-header .close {
  opacity: 1 !important;
  color: #0a1f31 !important;
  font-size: 2rem !important;
  outline: none !important;
}

textarea:hover,
input:hover,
textarea:active,
input:active,
textarea:focus,
input:focus,
button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active {
  outline: 0px !important;
  -webkit-appearance: none;
  box-shadow: none !important;
}

.category-form {
  height: 3rem !important;
  background: transparent !important;
  border: #dededf 1px solid !important;
  color: #0a1f31 !important;
}

.category-form::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: grey !important;
  opacity: 1; /* Firefox */
}

.category-form:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: grey !important;
}

.category-form::-ms-input-placeholder {
  /* Microsoft Edge */
  color: grey !important;
}

.category-form-area {
  height: 6rem !important;
}

br {
  display: block;
  content: "";
  margin-top: 12px;
}

.setting-heading {
  color: #3b424f;
  font-size: 20px;
  border-bottom: 1px solid #d1d1d1;
  padding-bottom: 12px;
}

.order-item-text {
  font-size: 18px;
  color: #0a1f31;
}

.order-note-text {
  font-size: 14px;
  color: #6d7477;
}

.order-total-text {
  font-size: 22px;
  color: #0a1f31;
}

.delivered-pagination .pagination ul {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
}

.delivered-pagination .pagination .item {
  border: 1px solid #e8e9ec;
  display: inline-block;
  margin-left: 3px;
  margin-right: 3px;
  border-radius: 4px;
  text-align: center;
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 13px;
  color: #4d4f5c;
  justify-content: center;
}

.delivered-pagination .pagination .item.active {
  background-color: #a7a8ab;
}

.disabled-page {
  color: #808e9b;
}

.pagination-page {
}

.break-me {
}

.delivered-pagination {
  justify-content: flex-end;
  display: flex;
  flex-direction: row;
}

.delivered-pagination .pagination .item a {
  font-size: 13px;
  color: #4d4f5c;
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.delivered-pagination .pagination .item.active a {
  color: #fff;
}

.delivered-pagination .pagination .item a:focus {
  outline: none;
}

.img-avatar {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.handle {
  padding: 0 5px;
  cursor: grab;
}

table.table thead th {
  color: #3dae2b !important;
  font-weight: 600;
}
